import DashboardSharpIcon from "@mui/icons-material/DashboardSharp";
import Shop2SharpIcon from "@mui/icons-material/Shop2Sharp";
import LocalShippingSharpIcon from "@mui/icons-material/LocalShippingSharp";
import TransferWithinAStationSharpIcon from "@mui/icons-material/TransferWithinAStationSharp";
import WarehouseSharpIcon from "@mui/icons-material/WarehouseSharp";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import StickyNote2OutlinedIcon from "@mui/icons-material/StickyNote2Outlined";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import LuggageSharpIcon from "@mui/icons-material/LuggageSharp";
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import ShortcutIcon from '@mui/icons-material/Shortcut';
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import FastForwardIcon from "@mui/icons-material/FastForward";
import DirectionsBoatFilledOutlinedIcon from '@mui/icons-material/DirectionsBoatFilledOutlined';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
const TabData = [
  {
    id: 0,
    parentnav: "Dashboard",
    icon: <DashboardSharpIcon />,
    // children: [],
    access: true,
    route: "/",
  },
  {
    id: 1,
    parentnav: "PO's",
    icon: <StickyNote2OutlinedIcon />,
    children: [
      {
        cid: 0,
        title: "PURCHASE ORDER",
        icon: <StickyNote2OutlinedIcon />,
      },
      {
        cid: 1,
        childnav: "Pending Purchase Order",
        route: "/pending_purchase_order",
        access: true,
      },
      // {
      //   cid: 2,
      //   childnav: "Upload Purchase Order",
      //   route: "/purchase/uploadpo",
      //   access: true,
      // },

      {
        cid: 3,
        childnav: "Accepted Purchase Order ",
        route: "/accepted_purchase_order",
        access: true,
      },
      {
        cid: 4,
        childnav: "Rejected Purchase Order ",
        route: "/rejected_purchase_order",
        access: true,
      },
    ],
    access: true,
  },
  {
    id: 2,
    parentnav: "Shipments",
    icon: <LocalShippingSharpIcon />,
    children: [
      {
        cid: 0,
        title: "FORWARDER",
        icon: <FastForwardIcon />,
      },
      {
        cid: 7,
        childnav: "Booking Request",
        route: "/booking_request",
        access: true,
      },
      {
        cid: 1,
        childnav: "Pending Forwarder Confirmation",
        route: "/pending_forwarder_confirmation",
        access: true,
      },
      {
        cid: 2,
        childnav: "Confirmed By Forwarder",
        route: "/confirmed_by_forwarder",
        access: true,
      },

      {
        cid: 3,
        childnav: "Rejected By Forwarder",
        route: "/rejected_by_forwarder",
        access: true,
      },
      {
        cid: 4,
        title: "CARRIER",
        icon: <DirectionsBoatFilledOutlinedIcon />,
      },
      {
        cid: 4,
        childnav: "Pending Carrier Confirmation",
        route: "/pending_carrier_confirmation",
        access: true,
      },
      {
        cid: 5,
        childnav: "Confirmed By Carrier",
        route: "/confirmed_by_carrier",
        access: true,
      },
      {
        cid: 6,
        childnav: "Rejected By Carrier",
        route: "/rejected_by_carrier",
        access: true,
      },
    ],
    access: true,
  },
  {
    id: 3,
    parentnav: "InTransit",
    icon: <TransferWithinAStationSharpIcon />,
    route: "/intransit_shipments",
    // children: [
    //   {
    //     cid: 0,
    //     title: "INTRANSIT",
    //     icon: <TransferWithinAStationSharpIcon />,
    //   },
    //   {
    //     cid: 2,
    //     childnav: "InTransit Shipments",
    //     route: "/intransit_shipments",
    //     access: true,
    //   },
    // ],
    access: true,
  },
  {
    id: 4,
    parentnav: "Delivered Report",
    icon: <AllInboxIcon />,
    route: "/delivered_report",
    access: true,
  },

  {
    id: 4,
    parentnav: "WareHouse",
    icon: <WarehouseSharpIcon />,
    access: true,
    children: [
      {
        cid: 0,
        title: "WAREHOUSE",
        icon: <WarehouseSharpIcon />,
      },
      {
        cid: 1,
        route: "/wareHouse_request_upload",
        childnav: "WareHouse Request Upload",
        access: false,
      },

      {
        cid: 4,
        route: "/pending_requests",
        childnav: "Pending Requests",
        access: false,
      },
      {
        cid: 2,
        route: "/confirmed_shipments",
        childnav: "Confirmed Shipments",
        access: false,
      },
      {
        cid: 5,
        route: "/rejected_shipments",
        childnav: "Rejected Shipments",
        access: false,
      },

      {
        cid: 6,
        route: "/inward_truckdetails",
        childnav: "InWard TruckDetails",
        access: false,
      },
      {
        cid: 7,
        route: "/shipments_at_warehouse",
        childnav: "Shipments At WareHouse",
        access: false,
      },

      {
        cid: 8,
        route: "/outward_shipments",
        childnav: "OutWard Shipments",
        access: false,
      },

      {
        cid: 1,
        route: "/space_utilization_report",
        childnav: "Space Utilization Report",
        access: false,
      },
    ],
  },
  {
    id: 6,
    parentnav: "Returns",
    icon: <LuggageSharpIcon />,
    children: [
      {
        cid: 0,
        title: "RETURNS",
        icon: <KeyboardReturnIcon />,
      },
      {
        cid: 1,
        childnav: "Upload Return Order",
        route: "/upload_return_order",
        access: true,
      },
      {
        cid: 1,
        childnav: "Return Orders For Acknowledgement",
        route: "/return_orders_for_acknowledgement",
        access: true,
      },
      {
        cid: 3,
        childnav: "Accepted Return Orders ",
        route: "/confirmed_return_orders",
        access: true,
      },
      {
        cid: 4,
        childnav: "Rejected Return Order ",
        route: "/rejected_return_orders",
        access: true,
      },
    ],
    access: true,
  },
  {
    id: 7,
    parentnav: "UploadDoc",
    icon: <UploadFileOutlinedIcon />,
    access: true,
    route: "/upload_documents",
    // children: [
    //   {
    //     cid: 0,
    //     title: "UPLOAD DOCUMENTS",
    //     icon: <UploadFileOutlinedIcon />,
    //   },
    //   {
    //     id: 1,
    //     childnav: "Upload Documents",
    //     route: "/upload_documents",
    //     access: true,
    //   },
    //   // {
    //   //   id: 2,
    //   //   childnav: "Manage Documents",
    //   //   route: "/manage_documents",
    //   //   access: true,
    //   // },
    // ],
  },
  {
    id: 8,
    parentnav: "Registrations",
    icon: <HowToRegOutlinedIcon />,
    children: [
      {
        cid: 0,
        title: "REGISTRATIONS",
        icon: <HowToRegOutlinedIcon />,
      },

      // process.env.REACT_APP_ENV != 'multitenancy' ?
      {
        cid: 1,
        childnav: "Partner Onboarding",
        route: "/registrations",
        access: true,
      },
      // : '',
      {
        cid: 2,
        childnav: "Shipment Registrations",
        route: "/shipmentRegistrations",
        access: true,
      },
      process.env.REACT_APP_ENV != 'multitenancy' ?
      {
        cid: 3,
        childnav: "Registred Shipments",
        route: "/registred_Shipments",
        access: true,
      }:""
    ],
    access: true,
  },
  {
    id: 9,
    parentnav: "digitalThread",
    icon: <WarehouseSharpIcon />,
    route: "/digitalThread",
    access: true,
  },
  {
    id: 10,
    parentnav: "Instore",
    icon: <StorefrontOutlinedIcon />,
    route: "/duty_free_retail_onboard",
    access: true,
  },
];
export default TabData;
